<template>
  <div class="foot_main">
    <div
      class="foot_banner"
      :style="{
        margin: height ? height + 'auto 0' : '6.578947rem' + ' auto 0'
      }"
      v-if="isBanner"
    >
      <div class="foot_banner_text">
        <div>7*24小时</div>
        <p>顶级客服团队为您提供全天候的客户服务</p>
      </div>
      <button class="btn" @click="download">立即下载</button>
    </div>
    <div class="split">
      <div></div>
      <div></div>
    </div>
    <div class="foot_context">
      <div class="foot_nav">
        <img src="@/assets/img/pc/trial/trial_code.jpg" class="img" />
        <div class="introduce">
          <div v-for="item in list" :key="item.id">
            <p v-for="item1 in item.name" :key="item1.id" @click="changeUrl(item1)">
              {{ item1.text }}
            </p>
          </div>
        </div>
        <div class="apps">
          <p>下载</p>
          <div @click="download">
            <p><img src="@/assets/img/pc/home/foot_nav_win.png" />Windows</p>
            <p><img src="@/assets/img/pc/home/foot_nav_linux.png" />Linux</p>
          </div>
        </div>
      </div>
      <div class="foot_address">
        版权所有 Copyright © 2019 湖南优享云通信技术有限公司 地址：长沙市天心区天经国际智能产业园1栋1201室
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备19011646号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBanner: {
      type: Boolean,
      default: true
    },
    data: {},
    height: {}
  },
  computed: {
    list() {
      if (!this.data) {
        return this.$store.state.introduceList
      }
      return this.data
    }
  },
  // props: ["data", "height"],
  methods: {
    download() {
      let size = window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize.substring(0, 2)
      // console.log(size);
      size = 27.368421 * parseInt(size) - 80
      // console.log(size);
      if (this.$route.path != '/download') {
        this.$router.push('/download')
        this.$nextTick(() => {
          document.documentElement.scrollTop = size
        })
      } else {
        document.documentElement.scrollTop = size
        return
      }
    },
    changeUrl(item) {
      if (item.path) {
        this.$router.push(item.path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.foot_main {
  overflow: hidden;
  // height: 31.578947rem;
  padding-bottom: 1.578947rem;
  .foot_banner {
    // margin: 13.157894rem auto 0;
    // margin: 6.578947rem auto 0;
    width: 36.842105rem;
    height: 9.210526rem;
    background-image: url('~@/assets/img/common/foot_banner.jpg');
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2.894736rem;
    background-repeat: no-repeat;
    .foot_banner_text {
      color: #ffffff;
      div {
        font-size: 1.105263rem;
        margin-bottom: 0.921052rem;
      }
      p {
        font-size: 0.68421rem;
      }
    }
    .btn {
      cursor: pointer;
      width: 6.973684rem;
      height: 2.236842rem;
      background-color: #fff;
      border: none;
      border-radius: 1.105263rem;
      font-size: 0.842105rem;
      color: #7d6bef;
    }
  }
  .split {
    width: 100%;
    height: 0.263157rem;
    display: flex;
    margin-top: 1rem;
    div {
      &:first-child {
        width: 30%;
        background-color: #24a643;
        margin-right: 0.131578rem;
      }
      &:last-child {
        flex: 1;
        background-color: #6764f1;
      }
    }
  }
  .foot_nav {
    width: 36.842105rem;
    height: 5.921052rem;
    margin: 3.605263rem auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img {
      height: 6.842105rem;
      width: 6.842105rem;
    }
    .introduce {
      align-self: flex-start;
      width: 10.526315rem;
      display: flex;
      justify-content: space-between;
      // flex-direction: column;
      p {
        cursor: pointer;
        margin: 0 0 0.526315rem;
        color: #6359a0;
        font-size: 0.421052rem;
        &:first-child {
          cursor: default;
          margin-bottom: 0.921052rem;
          color: #2e2661;
          font-size: 0.473684rem;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .apps {
      align-self: flex-start;
      p {
        cursor: default;
        margin: 0 0 1.052631rem 0;
        padding: 0;
        color: #2e2661;
        font-size: 0.473684rem;
      }
      width: 13.421052rem;
      div {
        width: 12.236842rem;
        height: 4.210526rem;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        margin-top: 0.526315rem;
        p {
          cursor: pointer;
          width: 5.789473rem;
          height: 1.736842rem;
          background-color: #6764f1;
          display: flex;
          align-items: center;
          // justify-content: center;
          font-size: 0.473684rem;
          color: #ffffff;
          margin: 0 0px 0 0;
          border-radius: 0.210526rem;
          img {
            width: 0.947368rem;
            height: 0.789473rem;
            margin: 0 0.526315rem 0 0.921052rem;
          }
          // &:first-child {
          //   // margin-bottom: 0.789473rem;
          // }
        }
      }
    }
  }
  .foot_address {
    text-align: center;
    margin-top: 3.157894rem;
    font-size: 0.421052rem;
    color: #6359a0;
    a {
      cursor: pointer;
    }
  }
}
.foot_context {
  overflow: hidden;
  background-image: url('~@/assets/img/pc/home/foot_background.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
